import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-terminos',
  templateUrl: './terminos.component.html',
  styleUrls: ['./terminos.component.css'],
})
export class TerminosComponent implements OnInit {
  id: string;
  mensaje: string;
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get('id');
    });
    this.mensaje = 'English';
  }

  post() {
    if (this.mensaje === 'English') {
      this.mensaje = 'Español';
    } else {
      this.mensaje = 'English';
    }
  }
}
