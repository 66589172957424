<div fxLayout="row" fxLayoutAlign="center center" class="login-style">
  <mat-card class="login-card mt-10vh" style="width: 350px">
    <mat-card-header fxLayoutAlign="center center">
      <mat-card-title
        ><h1 style="color: #757575">
          INGRESE NUEVA CONTRASEÑA
        </h1></mat-card-title
      >
    </mat-card-header>
    <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
      <mat-card-content class="auth-material-card-content">
        <p>
          <mat-form-field appearance="outline" fxFlex="100%">
            <mat-label>Ingrese nueva contraseña</mat-label>
            <input
              type="password"
              matInput
              placeholder="Contraseña"
              formControlName="password"
            />
            <mat-icon matSuffix>lock_outline</mat-icon>
            <mat-error
              *ngIf="resetPasswordForm.controls['password'].errors?.required"
              >La contraseña es requerida.</mat-error
            >
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="outline" fxFlex="100%">
            <mat-label>Confirmar Contraseña</mat-label>
            <input
              type="password"
              matInput
              placeholder="Confirmar Contraseña"
              formControlName="confirmPassword"
            />
            <mat-icon matSuffix>mail_outline</mat-icon>
            <mat-error
              *ngIf="
                resetPasswordForm.controls['confirmPassword'].errors?.required
              "
              >El campo es requerido.</mat-error
            >
            <mat-error
              *ngIf="
                resetPasswordForm.controls['confirmPassword'].errors?.isSame
              "
              >Las contraseñas deben coincidir.</mat-error
            >
          </mat-form-field>
        </p>
      </mat-card-content>
      <mat-card-actions class="auth-material-card-actions">
        <div fxLayout="row">
          <button
            type="submit"
            mat-raised-button
            color="primary"
            fxFlex="100%"
            [disabled]="resetPasswordForm.invalid"
          >
            GUARDAR
          </button>
        </div>
      </mat-card-actions>
    </form>
  </mat-card>
</div>
<div
  *ngIf="isLoading"
  fxLayoutAlign="center"
  style="position: absolute; top: 40%; right: 35%; left: 35%"
>
  <mat-spinner></mat-spinner>
</div>
