import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmPasswordValidator } from 'src/app/helpers/helper-methods';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.css']
})
export class PasswordRecoveryComponent implements OnInit {
  resetPasswordForm: FormGroup;
  isLoading = true;
  constructor(private formBuilder: FormBuilder,
              private auth: AuthService,
              private route: Router,
              private toastr: ToastrService) {

                const userValue = this.auth.currentUserValue;
                if (!userValue) {
                  route.navigate(['/search-courier']);
                }
               }

  ngOnInit() {
    this.resetPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    }, {
      validators: ConfirmPasswordValidator('password', 'confirmPassword')
    });
    this.isLoading = false;
  }

  get f() { return this.resetPasswordForm.controls; }
  onSubmit() {
    this.isLoading = true;
    if (this.resetPasswordForm.valid){
      const user = this.auth.currentUserValue;
      this.auth.resetPassword(this.f.password.value, user.id).subscribe(() => {
        this.isLoading = false;
        this.route.navigate(['/search-courier']);
      },
      () => {
        this.isLoading = false;
        this.toastr.error('There was an error saving your password. Please try again later.');
      });
    }
  }
}

