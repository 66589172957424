<div fxLayout="row" fxLayoutAlign="center center" class="login-style">
  <mat-card class="login-card mt-10vh" fxFlex="350px" fxFlex.lt-sm="90%">
    <mat-card-header fxLayoutAlign="center center">
      <mat-card-title><h1 style="color: #757575">ENTRAR</h1></mat-card-title>
    </mat-card-header>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <mat-card-content class="auth-material-card-content">
        <p>
          <mat-form-field appearance="outline" fxFlex="100%">
            <mat-label>Entre su correo electronico</mat-label>
            <input
              type="email"
              matInput
              placeholder="Email"
              formControlName="email"
            />
            <mat-icon matSuffix>mail_outline</mat-icon>
            <mat-error *ngIf="loginForm.controls['email'].errors?.required"
              >El correo es requerido.</mat-error
            >
            <mat-error *ngIf="loginForm.controls['email'].errors?.email"
              >Correo invalido.</mat-error
            >
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="outline" fxFlex="100%">
            <mat-label>Entre su contraseña</mat-label>
            <input
              matInput
              placeholder="Password"
              formControlName="password"
              [type]="passWordHide ? 'password' : 'text'"
            />
            <mat-icon
              matSuffix
              (click)="passWordHide = !passWordHide"
              class="cursor-pointer"
            >
              {{ passWordHide ? "visibility_off" : "visibility" }}
            </mat-icon>
            <mat-error *ngIf="loginForm.controls['password'].errors?.required"
              >Contraseña es requeridda.</mat-error
            >
          </mat-form-field>
        </p>
      </mat-card-content>
      <mat-card-actions class="auth-material-card-actions">
        <div
          fxLayout="row"
          fxLayout.lt-sm="column"
          fxLayoutAlign="space-between center"
        >
          <div [hidden]="visible">
            <p style="font-size: 15px">
              <mat-checkbox>Recordar contraseña</mat-checkbox>
            </p>
          </div>
          <div>
            <p style="font-size: 14px">
              <span
                ><a [routerLink]="['/reset-password']"
                  >Olvidó su contraseña?</a
                ></span
              >
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <button
            type="submit"
            mat-raised-button
            color="primary"
            fxFlex="100%"
            [disabled]="loginForm.invalid"
          >
            ENTRAR
          </button>
        </div>
        <div class="action-or"></div>
        <div fxLayout="row">
          <button
            mat-stroked-button
            [routerLink]="['/register']"
            fxFlex="100%"
            class="mb-10px"
          >
            REGISTRARSE
          </button>
        </div>
      </mat-card-actions>
    </form>
  </mat-card>
</div>
<div
  *ngIf="isLoading"
  fxLayoutAlign="center"
  style="position: absolute; top: 40%; right: 35%; left: 35%"
>
  <mat-spinner></mat-spinner>
</div>
