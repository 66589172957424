import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/main/confirmation-dialog/confirmation-dialog.component';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private toastr: ToastrService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.resetPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  getTempPassword() {
    if (this.resetPasswordForm.invalid) {
      return;
    }

    this.auth
      .getTempPassword(this.resetPasswordForm.controls.email.value)
      .subscribe(
        () => {
          const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
              message:
                'Las instrucciones para restablecer su contraseña han sido enviadas a su correo electrónico. Por favor revise su correo electrónico.',
              buttonText: {
                ok: 'Ok',
                cancel: null,
              },
            },
          });
          dialogRef.afterClosed().subscribe(() => {
            this.router.navigate(['/login']);
          });
        },
        (error: any) => {
          this.toastr.error(
            'There was an error sending the email to reset your password. Please try again later.'
          );
        }
      );
  }
}
